import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import ProfileContainer from 'components/Sections/Profile/ProfileContainer'
import PageTitle from 'components/UIElements/PageTitle'
import ArticlesList from 'components/Sections/Articles/ArticlesList'
import Blockquote from 'components/UIElements/Blockquote'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { IContextWithPageNav } from 'models/Context'
import { IOptionalMetaProps } from 'models/SEO'
import { IProfileHeader, IProfileMenuItem } from 'models/Profile'
import { IArticleCard } from 'models/Article'
import { IUser } from 'models/User'

type UserArticlesPagesProps = {
    user: IUser
    translations: {
        totalCount: number
    }
    articles: {
        totalCount: number
        nodes: IArticleCard[]
    }
}

const UserArticlesPages: React.FC<
    PageProps<UserArticlesPagesProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const { currentPage, totalPages } = context
    const user = data.user
    const totalTranslations = data.translations.totalCount
    const totalArticles = data.articles.totalCount
    const seo: IOptionalMetaProps = {
        title: user.name,
        description: `Artículos escritos por ${user.name}.`,
        image: user.avatar.file?.url,
    }

    const { name, avatar, cover, username } = user
    const { spotify, twitter, youtube, instagram, web } = user

    const headerProps: IProfileHeader = {
        type: 'Usuario',
        name,
        subname: `@${username}`,
        avatar,
        cover,
        social: {
            spotify,
            twitter,
            youtube,
            instagram,
            web,
        },
    }

    const menu: IProfileMenuItem[] = [
        { text: `Perfil`, to: `/users/${username}` },
        {
            text: `Artículos`,
            to: `/users/${username}/articles`,
            count: totalArticles,
        },
        {
            text: `Traducciones`,
            to: `/users/${username}/translations`,
            count: totalTranslations,
        },
    ]

    const articles = data.articles.nodes

    return (
        <Layout seo={seo}>
            <ProfileContainer menu={menu} {...headerProps}>
                <PageTitle title="Artículos" />
                {articles.length > 0 ? (
                    <ArticlesList articles={articles} />
                ) : (
                    <Blockquote>No hay nada para mostrar...</Blockquote>
                )}
                <PageNav
                    to={`/users/${user.username}/articles`}
                    totalPages={totalPages}
                    currentPage={currentPage}
                />
            </ProfileContainer>
        </Layout>
    )
}

export default UserArticlesPages

export const pageQuery = graphql`
    query UserArticlesPages($username: String!, $limit: Int!, $skip: Int!) {
        user: contentfulUsers(username: { eq: $username }) {
            name
            username
            birthday

            description {
                raw
            }

            avatar {
                gatsbyImageData(width: 200)
                file {
                    url
                }
            }
            cover {
                gatsbyImageData(layout: CONSTRAINED)
            }

            twitter
            instagram
            spotify
            youtube
            web
        }

        articles: allContentfulArticles(
            limit: $limit
            skip: $skip
            sort: { fields: date, order: DESC }
            filter: { author: { username: { eq: $username } } }
        ) {
            totalCount
            nodes {
                title
                slug
                date
                excerpt {
                    text: excerpt
                }
                author {
                    name
                    username
                    avatar {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                categories {
                    name
                    slug
                    color
                }
                thumbnail {
                    gatsbyImageData(width: 750)
                }
            }
        }

        translations: allContentfulSongs(
            filter: {
                translators: { elemMatch: { username: { eq: $username } } }
            }
        ) {
            totalCount
        }
    }
`
