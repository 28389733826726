// extracted by mini-css-extract-plugin
export var article_card = "ArticleCard-module--article_card--3Uhd3";
export var article_card_thumbnail_wrapper = "ArticleCard-module--article_card_thumbnail_wrapper--ux70q";
export var article_card_thumbnail = "ArticleCard-module--article_card_thumbnail--PF-FU";
export var article_card_data = "ArticleCard-module--article_card_data--js7zh";
export var article_card_title = "ArticleCard-module--article_card_title--3QZwn";
export var is_new = "ArticleCard-module--is_new--aDxe5";
export var article_card_categories = "ArticleCard-module--article_card_categories--uVJdA";
export var article_card_author_and_date = "ArticleCard-module--article_card_author_and_date--ODn4V";
export var icon = "ArticleCard-module--icon--OrvkR";
export var article_card_excerpt = "ArticleCard-module--article_card_excerpt--P8G5Z";