import React from 'react'
import { Link } from 'gatsby'
import { getDifferenceBetweenTwoDatesInDays, getFormattedDate } from 'util/date'
// Models
import { IArticleCard } from 'models/Article'
import { GatsbyImage } from 'gatsby-plugin-image'
// Styles
import * as styles from './ArticleCard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type ArticleCardProps = {
    article: IArticleCard
}

const __DAYS_UNTIL_GET_OLD__ = 8

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
    const { author, categories, title, thumbnail, slug, date, excerpt } =
        article
    const isNew =
        getDifferenceBetweenTwoDatesInDays(date) < __DAYS_UNTIL_GET_OLD__
            ? true
            : false
    const created_date = getFormattedDate(date)
    return (
        <article className={styles.article_card}>
            <Link
                className={styles.article_card_thumbnail_wrapper}
                to={`/articles/${slug}`}
            >
                <GatsbyImage
                    className={styles.article_card_thumbnail}
                    image={thumbnail.gatsbyImageData}
                    alt={title}
                />
            </Link>
            <div className={styles.article_card_data}>
                <Link to={`/articles/${slug}`}>
                    <h2
                        className={`${styles.article_card_title}${
                            isNew ? ` ${styles.is_new}` : ``
                        }`}
                    >
                        {title}
                    </h2>
                </Link>
                <div className={styles.article_card_categories}>
                    {categories.map((c) => (
                        <Link
                            style={{ backgroundColor: c.color }}
                            key={c.slug}
                            to={`/category/${c.slug}`}
                        >
                            {c.name}
                        </Link>
                    ))}
                </div>
                <div className={styles.article_card_author_and_date}>
                    <span>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={['fas', 'user']}
                        />
                        <Link to={`/users/${author.username}`}>
                            {author.name}
                        </Link>
                    </span>
                    <span>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={['far', 'calendar-alt']}
                        />
                        {created_date}
                    </span>
                </div>
                <div className={styles.article_card_excerpt}>
                    {excerpt.text}
                </div>
            </div>
        </article>
    )
}

export default ArticleCard
