import React from 'react'
// Components
import ArticleCard from './ArticleCard'
// Models
import { IArticleCard } from 'models/Article'

type ArticlesListProps = {
    articles: IArticleCard[]
}

const ArticlesList: React.FC<ArticlesListProps> = ({ articles }) => {
    return (
        <>
            {articles.map((article) => (
                <ArticleCard key={article.slug} article={article} />
            ))}
        </>
    )
}

export default ArticlesList
